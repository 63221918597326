import * as React from 'react';

export const HighlightDiamond = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={48}
    height={48}
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path fill="#fff" d="M0 0h48v48H0z" />
    <path
      d="M25.278 25.652l15.033 8.768c.763.454.763 1.198 0 1.652l-14.675 8.475c-.56.455-1.471.455-2.03 0L8.571 35.78c-.763-.455-.763-1.198 0-1.653l14.676-8.474c.559-.455 1.47-.455 2.03 0z"
      fill="#FFEFDC"
    />
    <path
      d="M40.028 14.436l-3.763-5.922C35.671 7.579 34.578 7 33.412 7h-17.94c-1.166 0-2.26.58-2.854 1.514l-3.762 5.922a2.908 2.908 0 000 3.146l11.551 16.316a4.606 4.606 0 002.617 1.923h.007c.448.132.922.202 1.412.202.49 0 .964-.07 1.414-.2h.005c1.084-.315 2.016-.983 2.617-1.923l11.527-16.283.022-.035a2.908 2.908 0 000-3.146zm-13.425-5.82l4.18 6.583h-12.68l4.18-6.584h4.32zm-12.618.762c.298-.472.87-.763 1.486-.763h4.894l-4.18 6.584H10.29l3.695-5.82zm-3.688 7.439h5.756l5.59 16.027-11.346-16.027zM25 34.353c-.182.03-.368.05-.56.05-.19 0-.375-.02-.559-.05l-6.116-17.536h13.348l-6.116 17.536H25zm2.241-1.511l5.59-16.025h5.755L27.242 32.842zM32.7 15.199l-4.18-6.584h4.893c.62 0 1.188.294 1.486.763l3.696 5.82h-5.895z"
      fill="#128656"
    />
  </svg>
);
