import * as React from 'react';

export const Gender = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={31}
    height={31}
    viewBox="0 0 31 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M30.1 15.1c0 .403-.403.806-.805.806h-13.39v13.389c0 .402-.402.805-.805.805s-.805-.403-.805-.805V17.113l-8.658 8.658c-.201.202-.403.202-.604.202-.201 0-.403-.101-.604-.202-.302-.302-.302-.805 0-1.208l8.658-8.657H.905c-.402 0-.805-.403-.805-.806s.403-.805.805-.805h13.39V.905c0-.402.402-.805.805-.805s.805.403.805.805v12.182l8.658-8.658c.302-.302.806-.302 1.208 0 .302.302.302.805 0 1.208l-8.658 8.658h12.182c.503 0 .805.402.805.805z"
      fill="#000"
      fillOpacity={0.87}
    />
  </svg>
);
