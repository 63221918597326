import * as React from 'react';

export const Languages = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={31}
    height={31}
    viewBox="0 0 31 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.1.1C6.79.1.1 6.79.1 15.1c0 8.31 6.69 15 15 15 8.31 0 15-6.69 15-15 0-8.31-6.69-15-15-15zm13.273 14.137h-5.287c-.108-4.856-1.511-9.065-3.454-11.655 4.856 1.727 8.31 6.26 8.741 11.655zM15.1 28.373c-2.914 0-5.935-4.964-6.151-12.41h12.41c-.324 7.446-3.345 12.41-6.259 12.41zM8.949 14.238c.216-7.446 3.345-12.41 6.151-12.41 2.806 0 5.935 4.964 6.151 12.41H8.95zm1.619-11.655c-1.943 2.482-3.346 6.69-3.454 11.655H1.827c.431-5.396 3.885-9.928 8.74-11.655zM1.827 15.963h5.287c.108 4.857 1.511 9.065 3.454 11.655-4.856-1.726-8.31-6.259-8.741-11.655zm17.805 11.655c1.943-2.482 3.346-6.69 3.454-11.655h5.287c-.431 5.396-3.885 9.929-8.74 11.655z"
      fill="#000"
      fillOpacity={0.87}
    />
  </svg>
);
