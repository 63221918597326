import * as React from 'react';

export const InPersonGreenCircle = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={74}
    height={74}
    viewBox="0 0 74 74"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M23.762 71.582c11.42 0 20.678-9.258 20.678-20.678 0-11.42-9.258-20.678-20.678-20.678-11.42 0-20.678 9.257-20.678 20.678 0 11.42 9.257 20.678 20.678 20.678z"
      fill="#D2F4E5"
    />
    <path
      d="M68.363 22.708c0 .69-.56 1.25-1.249 1.25h-3.92v13.294a4.485 4.485 0 01-4.479 4.48h-8.172l7.682 18.796a1.25 1.25 0 01-1.153 1.72c-.49 0-.958-.29-1.157-.773l-8.07-19.744h-20.85l-8.069 19.744a1.249 1.249 0 01-2.31-.947l7.682-18.797h-8.172a4.484 4.484 0 01-4.48-4.479V23.957H7.728a1.25 1.25 0 010-2.498h4.766c.912 0 1.652.74 1.652 1.652V35.769c0 3 .632 3.453 2.8 3.453h40.897c2.18 0 2.812-.774 2.812-3.453v-4.398c0-.111.02-.218.046-.322v-7.938c0-.912.74-1.652 1.651-1.652h4.767c.69 0 1.249.56 1.249 1.25h-.004zM18.838 32.62c.69 0 1.25-.56 1.25-1.249V10.977c0-.384.198-.77.643-.77h33.065a.77.77 0 01.77.77V31.37a1.25 1.25 0 002.498 0V10.977c0-1.801-1.463-3.269-3.268-3.269H20.727c-1.762 0-3.138 1.437-3.138 3.268v20.395c0 .69.56 1.25 1.25 1.25z"
      fill="#128656"
    />
  </svg>
);
