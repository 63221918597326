import * as React from 'react';

export const VideoChat = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={31}
    height={17}
    viewBox="0 0 31 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M29.535.97c-.38-.19-.854-.19-1.234 0L22.7 4.577V2.394C22.7 1.349 21.846.4 20.706.4H2.194C1.149.4.2 1.254.2 2.394v12.531c0 1.044.854 1.994 1.994 1.994h18.512c1.045 0 1.994-.855 1.994-1.994v-2.183l5.601 3.607c.19.095.38.19.665.19.19 0 .38-.095.57-.19.38-.19.664-.664.664-1.044V2.014c-.095-.38-.285-.855-.665-1.044zm-8.354 13.955c0 .19-.19.38-.38.38H2.194c-.19 0-.38-.19-.38-.38V2.394c0-.19.19-.38.38-.38h18.512c.19 0 .38.19.38.38v12.531h.095zm7.405-.19L22.7 10.938V6.476l5.886-3.798v12.057z"
      fill="var(--hw-color-primary-medium)"
    />
  </svg>
);
