import * as React from 'react';

export const Specialties = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={31}
    height={30}
    viewBox="0 0 31 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M24.448 29.052c-.196 0-.392-.098-.588-.197l-8.047-5.887-8.047 5.887c-.392.295-.883.295-1.177 0-.393-.294-.49-.686-.393-1.177l3.042-9.519-8.046-5.887c-.393-.295-.49-.687-.393-1.178.098-.392.491-.687.982-.687H11.79l2.944-9.42c.098-.393.49-.687.98-.687.492 0 .884.294.982.687l3.042 9.518h10.01c.49 0 .882.295.98.687.099.393 0 .883-.392 1.178l-8.046 5.888 3.041 9.518c.099.393 0 .883-.392 1.178-.098 0-.294.098-.49.098zm-21-16.976l7.557 5.495L8.06 26.5l7.556-5.496 7.556 5.495-2.944-8.93 7.556-5.495h-9.126l-2.944-8.93-2.944 8.93H3.449z"
      fill="var(--hw-color-primary-medium)"
    />
  </svg>
);
