import * as React from 'react';

export const YearsOfExperience = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18.0848 18.674C18.183 18.7723 18.2813 18.7723 18.3795 18.7723C18.4778 18.7723 18.576 18.7232 18.6743 18.6741L18.6743 18.674C18.8216 18.5267 18.8216 18.2811 18.6743 18.1337L12.3866 11.846V3.05299C12.3866 2.8565 12.1901 2.66 11.9936 2.66C11.7971 2.66 11.6006 2.8565 11.6006 3.05299V11.9933C11.6006 12.0916 11.6497 12.1898 11.6988 12.2881L18.0848 18.674ZM14.4497 9.88106C14.3515 9.88106 14.2532 9.83194 14.155 9.78281C14.0076 9.63544 14.0076 9.38983 14.155 9.24246L17.9865 5.41088C18.1339 5.26351 18.3795 5.26351 18.5269 5.41088C18.6743 5.55825 18.6743 5.80386 18.5269 5.95123L14.6953 9.78281C14.6953 9.88106 14.5971 9.88106 14.4497 9.88106ZM15.5302 12.3863H20.9337C21.1302 12.3863 21.3267 12.1898 21.3267 11.9933C21.3267 11.7968 21.1302 11.6004 20.9337 11.6004H15.5302C15.3337 11.6004 15.1372 11.7968 15.1372 11.9933C15.1372 12.1898 15.3337 12.3863 15.5302 12.3863ZM9.48831 9.88106C9.39006 9.88106 9.29182 9.83194 9.19357 9.78281L5.36199 5.95123C5.21462 5.80386 5.21462 5.55825 5.36199 5.41088C5.50936 5.26351 5.75497 5.26351 5.90234 5.41088L9.73392 9.24246C9.88129 9.38983 9.88129 9.63544 9.73392 9.78281C9.6848 9.88106 9.58656 9.88106 9.48831 9.88106ZM3.05314 12.3863H8.45665C8.70226 12.3863 8.84963 12.1898 8.84963 11.9933C8.84963 11.7968 8.65314 11.6004 8.45665 11.6004H3.05314C2.85665 11.6004 2.66016 11.7968 2.66016 11.9933C2.66016 12.1898 2.85665 12.3863 3.05314 12.3863ZM5.65673 18.7232C5.55849 18.7232 5.46024 18.674 5.362 18.6249L5.36199 18.6249C5.21462 18.4776 5.21462 18.2319 5.36199 18.0846L9.19357 14.253C9.34094 14.1056 9.58656 14.1056 9.73392 14.253C9.88129 14.4004 9.88129 14.646 9.73392 14.7933L5.90234 18.6249C5.85322 18.674 5.75497 18.7232 5.65673 18.7232ZM11.6006 20.9337C11.6006 21.1302 11.7971 21.3267 11.9936 21.3267C12.1901 21.3267 12.3866 21.1302 12.3866 20.9337V15.5302C12.3866 15.3337 12.1901 15.1372 11.9936 15.1372C11.7971 15.1372 11.6006 15.3337 11.6006 15.5302V20.9337Z"
      fill="#128756"
    />
  </svg>
);
