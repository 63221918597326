import * as React from 'react';

export const Calendar = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={16}
    height={17}
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.491 1.448h-2.942V.444a.444.444 0 00-.889 0v1.004H4.924V.444a.444.444 0 00-.889 0v1.004H1.094C.49 1.448 0 1.938 0 2.54v13.369C0 16.51.49 17 1.094 17H14.49c.602 0 1.093-.49 1.093-1.091V2.539c0-.602-.49-1.092-1.093-1.092l.001.001zm.204 14.46a.204.204 0 01-.204.205H1.095a.204.204 0 01-.205-.204V2.539c0-.114.092-.205.205-.205h2.942v1.004a.444.444 0 00.889 0V2.334h5.736v1.004a.444.444 0 00.889 0V2.334h2.941c.114 0 .205.091.205.204v13.37h-.002zm-2.881-8.835l-4.061 4.985a.52.52 0 01-.374.19h-.026a.516.516 0 01-.364-.152L4.53 9.643a.444.444 0 11.629-.628l2.166 2.161 3.799-4.664a.443.443 0 11.689.56l.001.001z"
      fill="#128656"
    />
  </svg>
);
