export const PATIENT_WELCOME_EMAIL =
  'https://findheadway.zendesk.com/hc/en-us/articles/16763469418260';

export const COORDINATION_OF_BENEFITS_LINK =
  'https://findheadway.zendesk.com/hc/en-us/articles/8637737728532-Coordination-of-benefits';

export const COORDINATION_OF_BENEFITS_ANCHORED_LINK =
  'https://findheadway.zendesk.com/hc/en-us/articles/8637737728532-Coordination-of-benefits#h_01GKRWE5QYBTCRVK0AEE1JMV15';

export const CHANGE_HEALTHCARE_OUTAGE_LINK =
  'https://findheadway.zendesk.com/hc/en-us/articles/9919736938772';

export const LEARN_ABOUT_GETTING_PAID_LINK =
  'https://findheadway.zendesk.com/hc/en-us/articles/4416374825748';

export const LEARN_ABOUT_GETTING_CREDENTIALED_LINK =
  'https://help.headway.co/hc/en-us/articles/360058294552';

export const CONTACT_FORM_LINK =
  'https://findheadway.zendesk.com/hc/en-us/requests/new';

export const SELF_PAY_LINK =
  'https://findheadway.zendesk.com/hc/en-us/articles/9565199998484-Private-Pay';

export const BCBSMA_VIRTUAL_NETWORK_LINK =
  'https://findheadway.zendesk.com/hc/en-us/articles/17753323288852';

export const BCBSNJ_VIRTUAL_NETWORK_LINK =
  'https://help.headway.co/hc/en-us/articles/23525279542804';

export const TELEHEALTH_POLICIES_LINK =
  'https://help.headway.co/hc/en-us/articles/4421041745684';

export const CREDENTIALING_REQS_LINK =
  'https://help.headway.co/hc/en-us/articles/26337138441236-Credentialing-licensing-and-location-requirements#h_01HX738T5V3H2VWMNP11N0Z1E4';
