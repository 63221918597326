import * as React from 'react';

export const InPerson = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={31}
    height={27}
    viewBox="0 0 31 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M23.502 12.49c-.356 0-.712-.356-.712-.712V1.991c0-.178-.09-.267-.267-.267H7.753c-.179 0-.268.089-.268.267v9.787c0 .356-.356.712-.712.712-.355 0-.711-.356-.711-.712V1.991c0-.98.8-1.691 1.69-1.691h14.86c.979 0 1.69.8 1.69 1.69v9.788c-.088.445-.355.712-.8.712zm5.961-5.605H27.15a.892.892 0 00-.89.89v6.762c0 .445-.356.8-.8.8H4.904a.797.797 0 01-.8-.8V7.774a.892.892 0 00-.89-.89H.811c-.356 0-.712.356-.712.712s.356.712.712.712h1.78v6.229c0 1.245.978 2.313 2.313 2.313h3.737l-3.648 8.898c-.178.356 0 .801.356.979.089 0 .178.089.267.089.267 0 .534-.178.712-.445l3.826-9.432h9.877l3.826 9.432c.089.267.355.445.711.445.09 0 .178 0 .267-.089.356-.178.534-.534.356-.979l-3.648-8.898h3.737a2.297 2.297 0 002.314-2.313V8.308h1.78c.355 0 .711-.356.711-.712.09-.356-.267-.711-.623-.711z"
      fill="var(--hw-color-primary-medium)"
    />
  </svg>
);
