import * as React from 'react';

export const AcceptsInsurance = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={30}
    height={30}
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M29.503 3.874C20.563-1.29 9.338-1.29.397 3.874L0 4.073v.497C0 14.9 5.563 24.636 14.603 29.8L15 30l.397-.199C24.337 24.636 30 15 30 4.57v-.497l-.497-.199zM14.901 28.113A27.29 27.29 0 011.589 4.967c8.245-4.57 18.378-4.57 26.623 0A27.29 27.29 0 0114.9 28.113zm0-22.252c.397 0 .794.397.794.795v5.265h5.265c.398 0 .795.397.795.794 0 .398-.397.795-.795.795h-5.265v5.265c0 .397-.397.795-.794.795-.398 0-.795-.398-.795-.795V13.51H8.841c-.397 0-.795-.397-.795-.795 0-.397.398-.794.795-.794h5.265V6.656c0-.497.397-.795.795-.795z"
      fill="var(--hw-color-primary-medium)"
    />
  </svg>
);
