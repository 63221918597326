import * as React from 'react';

export const Education = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={31}
    height={30}
    viewBox="0 0 31 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.7 23.704c-.184 0-.46-.092-.644-.184L1.16 13.03C.884 12.844.7 12.476.7 12.2c0-.368.184-.644.46-.828L15.056.883c.368-.277.92-.277 1.288 0l13.896 10.49c.276.184.46.552.46.828 0 .368-.184.644-.46.828L16.344 23.52c-.184.092-.46.184-.644.184zM2.54 12.201l13.16 9.847L28.86 12.2 15.7 2.354 2.54 12.201zm14.172 16.933l7.454-5.522c.552-.368.828-1.012.828-1.656v-1.012c0-.369-.368-.737-.736-.737s-.736.368-.736.736v1.013a.702.702 0 01-.184.46l-7.454 5.521H15.7l-7.454-5.521c-.184-.092-.184-.276-.184-.46v-1.012c0-.369-.368-.737-.736-.737s-.736.368-.736.736v1.013c0 .644.276 1.288.828 1.656l7.454 5.522c.276.184.644.368 1.012.368.184-.092.46-.184.828-.368zm11.595-4.878v-6.994c0-.368-.368-.736-.736-.736s-.828.276-.828.736v6.994c0 .368.368.736.736.736s.828-.368.828-.736z"
      fill="#000"
      fillOpacity={0.87}
    />
  </svg>
);
