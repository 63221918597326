import { Link } from '@headway/helix/Link';
import {
  CONTACT_FORM_LINK,
  COORDINATION_OF_BENEFITS_ANCHORED_LINK,
} from '@headway/shared/constants/zendesk';

export const CoordinationOfBenefitsFreezeMessage = () => (
  <>
    Your Headway account is paused. In order to book sessions or upload new
    insurance details, you'll need to verify a few plan details with your
    insurance company.{' '}
    <Link
      href={COORDINATION_OF_BENEFITS_ANCHORED_LINK}
      target="_blank"
      rel="noreferrer"
    >
      Learn more
    </Link>
    .
  </>
);

export const OtherFreezeMessage = () => (
  <>
    Your Headway account is paused because there's an issue verifying your
    insurance plan coverage. Please reach out to us via{' '}
    <Link href={'/contact'} target="_blank" rel="noreferrer">
      our contact form
    </Link>{' '}
    with the front and back of your insurance card to resolve the issue.
  </>
);

export const OutOfNetworkFreezeMessage = () => (
  <>
    Your Headway account is paused because your plan is out-of-network. Until we
    accept your plan, we recommend asking your provider about private pay
    options. Your plan may also offer OON reimbursement. If your insurance
    information changes, please reach out to our team via{' '}
    <Link href={CONTACT_FORM_LINK} target="_blank" rel="noreferrer">
      our contact form
    </Link>
    .
  </>
);
