import * as React from 'react';

export const MobilePhone = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={8}
    height={12}
    viewBox="0 0 8 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.64.347H1.36a.95.95 0 00-.947.946v9.427c0 .52.427.947.947.947h5.28a.95.95 0 00.947-.947V1.293A.95.95 0 006.64.347zm.213 10.373a.21.21 0 01-.213.213H1.36a.21.21 0 01-.213-.213V1.293a.21.21 0 01.213-.213h5.28a.21.21 0 01.213.213v9.427zM5.8 9.853c0 .2-.16.36-.373.36h-2.88a.37.37 0 01-.374-.36c0-.2.16-.36.374-.36h2.88c.2 0 .373.16.373.36z"
      fill="#000"
      fillOpacity={0.87}
    />
  </svg>
);
