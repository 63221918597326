import * as React from 'react';

export const AgeGroups = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={30}
    height={21}
    viewBox="0 0 30 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M23.642 9.403c.537-.896.806-1.97.806-3.134 0-3.403-2.776-6.18-6.18-6.18-3.402 0-6.178 2.777-6.178 6.18 0 1.164.268 2.149.806 3.134-3.672 1.88-6.09 5.642-6.27 9.761 0 .358.09.627.36.896.268.268.536.358.895.358h20.865c.359 0 .627-.18.896-.358.268-.269.358-.538.358-.896-.269-4.12-2.687-7.88-6.358-9.761zm-5.373-7.88c2.597 0 4.746 2.149 4.746 4.746 0 2.597-2.15 4.746-4.746 4.746-2.597 0-4.747-2.15-4.747-4.746 0-2.597 2.15-4.747 4.747-4.747zM8.149 18.984c.269-3.582 2.418-6.895 5.732-8.418 1.164 1.164 2.686 1.88 4.477 1.88 1.791 0 3.314-.716 4.478-1.88 3.224 1.612 5.463 4.836 5.731 8.418H8.15zm-.985-8.418c-3.492 1.702-5.731 5.284-5.731 9.134 0 .359-.358.717-.717.717-.358 0-.716-.358-.716-.716 0-4.389 2.418-8.329 6.269-10.389-.538-.895-.806-1.97-.806-3.134C5.463 2.776 8.239 0 11.642 0c.358 0 .716.358.716.716 0 .359-.358.717-.716.717-2.597 0-4.746 2.15-4.746 4.746 0 1.702.895 3.224 2.328 4.12.358.179.448.626.269.985-.18.268-.359.358-.627.358-.09 0-.269 0-.359-.09-.447-.268-.895-.537-1.343-.985z"
      fill="#000"
      fillOpacity={0.87}
    />
  </svg>
);
