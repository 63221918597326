import * as React from 'react';

export const Ethnicities = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={31}
    height={31}
    viewBox="0 0 31 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M30.6 9.558C30.6 4.663 26.537.6 21.642.6c-2.292 0-4.48.938-6.042 2.396C14.037 1.538 11.85.6 9.558.6 4.663.6.6 4.663.6 9.558c0 2.292.833 4.375 2.292 6.042C1.433 17.163.6 19.246.6 21.642c0 4.896 4.063 8.958 8.958 8.958 2.292 0 4.48-.937 6.042-2.396 1.563 1.459 3.75 2.396 6.042 2.396 4.896 0 8.958-4.062 8.958-8.958 0-2.292-.833-4.375-2.292-6.042 1.459-1.562 2.292-3.646 2.292-6.042zm-1.667 0c0 1.875-.729 3.542-1.875 4.896-1.458-1.146-3.333-1.77-5.416-1.77-1.459 0-2.917.312-4.063 1.041.625-1.25.938-2.604.938-4.062 0-1.98-.625-3.855-1.771-5.313 1.25-1.146 3.02-1.875 4.896-1.875 3.958-.104 7.291 3.125 7.291 7.083zM15.6 25.6c-.73-1.146-1.25-2.5-1.25-4.062 0-1.563.417-2.917 1.25-4.063.73 1.146 1.25 2.5 1.25 4.063 0 1.562-.52 2.916-1.25 4.062zm0-20c.73 1.146 1.25 2.5 1.25 4.063 0 1.562-.52 2.812-1.25 3.958-.73-1.146-1.25-2.5-1.25-4.063 0-1.562.52-2.812 1.25-3.958zm10.104 10c-1.146.833-2.604 1.25-4.062 1.25-1.459 0-2.917-.417-4.063-1.25 1.146-.833 2.604-1.25 4.063-1.25 1.458 0 2.916.417 4.062 1.25zM9.558 2.37c1.875 0 3.646.73 4.896 1.876-1.146 1.458-1.77 3.333-1.77 5.312 0 1.459.312 2.813.937 4.063-1.25-.625-2.604-1.042-4.063-1.042-1.979 0-3.854.625-5.416 1.771-1.146-1.25-1.875-3.02-1.875-4.896 0-3.854 3.333-7.083 7.291-7.083zm4.063 13.23c-1.146.833-2.604 1.25-4.063 1.25-1.458 0-2.916-.417-4.062-1.25 1.146-.833 2.604-1.25 4.062-1.25 1.459 0 2.917.417 4.063 1.25zM2.267 21.642c0-1.875.729-3.542 1.875-4.896 1.458 1.146 3.333 1.77 5.416 1.77 1.459 0 2.917-.312 4.063-1.04-.625 1.25-.938 2.603-.938 4.062 0 1.979.625 3.854 1.771 5.312-1.25 1.146-3.02 1.875-4.896 1.875-3.958.104-7.291-3.125-7.291-7.083zm19.375 7.187c-1.875 0-3.646-.729-4.896-1.875 1.146-1.458 1.77-3.333 1.77-5.312 0-1.459-.312-2.813-.937-4.063 1.25.625 2.604 1.042 4.063 1.042 1.979 0 3.854-.625 5.416-1.77 1.146 1.25 1.875 3.02 1.875 4.895 0 3.854-3.333 7.083-7.291 7.083z"
      fill="var(--hw-color-primary-medium)"
      fillOpacity={0.87}
    />
  </svg>
);
