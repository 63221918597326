import { UserFreezeReason } from '@headway/api/models/UserFreezeReason';
import { UserFreezeApi } from '@headway/api/resources/UserFreezeApi';
import {
  QueryKey,
  useQuery,
  UseQueryOptions,
} from '@headway/shared/react-query';

import {
  ALL_USER_FREEZES_CACHE_KEY,
  USER_FREEZES_CACHE_KEY,
} from '../utils/cacheKeys';

export const getUseActiveUserFreezesQueryKey = (userId: number): QueryKey => [
  USER_FREEZES_CACHE_KEY,
  userId,
];

export const useActiveUserFreezes = (
  userId: number,
  options?: UseQueryOptions<UserFreezeReason[] | undefined>
) =>
  useQuery(
    getUseActiveUserFreezesQueryKey(userId),
    async () => await UserFreezeApi.getActiveFreezesForUser(userId),
    {
      staleTime: Infinity,
      ...options,
    }
  );

export const useAllUserFreezes = (userId: number | undefined) => {
  return useQuery(
    [ALL_USER_FREEZES_CACHE_KEY, userId],
    async () => await UserFreezeApi.getFreezesForUser(userId!),
    {
      enabled: !!userId,
    }
  );
};
