import * as React from 'react';

export const LicenseTypes = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={31}
    height={23}
    viewBox="0 0 31 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M29.882 17.649c-.409 0-.817-.408-.817-.816v-15H6.106c-.408 0-.816-.409-.816-.817S5.698.2 6.106.2h23.571C30.29.2 30.8.71 30.8 1.322v15.613c-.102.408-.408.714-.918.714zm-3.572 3.367V5.71c0-.612-.51-1.122-1.122-1.122H1.922C1.31 4.588.8 5.098.8 5.71v15.306c0 .613.51 1.123 1.122 1.123h23.266c.612 0 1.122-.51 1.122-1.123zM2.433 6.22h22.143v14.184H2.433V6.22z"
      fill="#000"
      fillOpacity={0.87}
    />
  </svg>
);
