import * as React from 'react';

export const Modalities = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={31}
    height={28}
    viewBox="0 0 31 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M21.102 8.388c-1.59 0-3.088.375-4.399 1.123l-4.68-8.05c-.187-.28-.562-.561-.936-.561a1.03 1.03 0 00-.936.562L.79 17.842c-.188.281-.188.749 0 1.03.187.28.561.561.935.561H11.93c.75 4.4 4.587 7.77 9.267 7.77 5.148 0 9.454-4.213 9.454-9.454 0-5.242-4.306-9.36-9.548-9.36zM2.475 17.936L11.18 2.959l4.306 7.395a9.35 9.35 0 00-3.744 7.488v.094H2.475zm10.765-.187c0-2.528 1.123-4.68 2.995-6.178l3.65 6.271h-6.552c-.093 0-.093 0-.093-.093zm7.862 7.956c-3.744 0-6.926-2.715-7.675-6.272h7.207c.375 0 .75-.187.936-.561.188-.281.188-.749 0-1.03l-4.024-7.02c1.123-.562 2.34-.936 3.65-.936 4.306 0 7.863 3.557 7.863 7.863 0 4.305-3.557 7.956-7.957 7.956z"
      fill="#000"
      fillOpacity={0.87}
    />
  </svg>
);
