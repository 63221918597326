import * as React from 'react';

export const HighlightYearsOfExperience = (
  props: React.SVGProps<SVGSVGElement>
) => (
  <svg
    width={48}
    height={48}
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M24 37.3c7.3 0 13.3-5.9 13.3-13.3s-6-13.3-13.3-13.3c-7.3 0-13.3 6-13.3 13.3 0 7.3 6 13.3 13.3 13.3z"
      fill="#FFEFDC"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M36.4 37.6c.2.2.4.2.6.2.2 0 .4-.1.6-.2.3-.3.3-.8 0-1.1L24.8 23.7V5.8c0-.4-.4-.8-.8-.8s-.8.4-.8.8V24c0 .2.1.4.2.6l13 13z"
      fill="#128656"
    />
    <path
      d="M29 19.7c-.2 0-.4-.1-.6-.2-.3-.3-.3-.8 0-1.1l7.8-7.8c.3-.3.8-.3 1.1 0 .3.3.3.8 0 1.1l-7.8 7.8c0 .2-.2.2-.5.2zM42.2 24.8h-11c-.4 0-.8-.4-.8-.8s.4-.8.8-.8h11c.4 0 .8.4.8.8s-.4.8-.8.8zM18.9 19.7c-.2 0-.4-.1-.6-.2l-7.8-7.8c-.3-.3-.3-.8 0-1.1.3-.3.8-.3 1.1 0l7.8 7.8c.3.3.3.8 0 1.1-.1.2-.3.2-.5.2zM16.8 24.8h-11c-.4 0-.8-.4-.8-.8s.4-.8.8-.8h11c.4 0 .8.4.8.8s-.3.8-.8.8zM11.1 37.7c-.2 0-.4-.1-.6-.2-.3-.3-.3-.8 0-1.1l7.8-7.8c.3-.3.8-.3 1.1 0 .3.3.3.8 0 1.1l-7.8 7.8c-.1.1-.3.2-.5.2zM24 43c-.4 0-.8-.4-.8-.8v-11c0-.4.4-.8.8-.8s.8.4.8.8v11c0 .4-.4.8-.8.8z"
      fill="#128656"
    />
  </svg>
);
